import React, { useEffect, useMemo, useReducer, useState } from "react";
import "./DictionaryDetails.scss";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { SidebarItemType } from "src/app/Sidebar/SidebarItems";
import { AddedBy } from "src/app/Task/components/AddedBy/AddedBy";
import { convertToLocalTimezone } from "src/app/methods/convertToLocalTimezone";
import { useQuery } from "src/app/methods/useQuery";
import { useShowPane } from "src/hooks/useShowPane";
import { ReactComponent as CloseIcon } from "src/images/cross-alt.svg";
import {
  clearDictionaryDetails,
  getDictionaryDetails,
  setSelectedTableView,
  deleteDictionaryElement,
} from "src/redux";
import { RootState } from "src/redux/reducers";
import dictionaryFiltersReducer, {
  dictionaryFiltersReducerInitialState,
} from "src/app/Dictionary/redux/dictionaryFiltersReducer";
import { generateMetadataRanks } from "src/app/CreatorDatabase/redux/creatorDatabaseFiltersReducer";
import TableViewSelector from "src/app/TableView/TableViewSelector";
import { getSelectDataSetForColumns } from "src/utils/methods";
import { Breadcrumbs, BreadcrumbsItem } from "../Breadcrumbs/Breadcrumbs";
import { Pane } from "../Pane/Pane";
import PreviewInfo from "../Preview/components/PreviewInfo";
import { PreviewContext } from "../Preview/utils";
import DictionaryMetadata from "./components/DictionaryMetadata/DictionaryMetadata";
import { closeDictionaryDetails } from "./methods";
import {
  resetHiddenColumns,
  setDictionaryFilter,
  setDictionaryFilters,
  setDictionaryInitialColumnRanks,
  setDictionarySort,
  setDictionarySortBy,
  setHiddenDictionaryColumnIds,
} from "../DictionaryFilters/redux/dictionaryFiltersActions";
import { PreviewSettingsDropdown } from "../GlobalTaskDetails/PreviewSettingsDropdown/PreviewSettingsDropdown";

export default function DictionaryDetails(props: any) {
  const { tableViewsFunctions } = props;

  const {
    mainReducer: { activeWorkspaceUuid, identity },
    dictionaryReducer: { dictionaryDetails, dictionaryList },
    projectReducer: { membersList },
  } = useSelector((state: RootState) => state);

  const { dictionaryUuid } = useParams<{ dictionaryUuid: string }>();
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const dictionaryElementUuid = query.get("displayDictionaryPreview") || "";

  const [showPane, hidePane] = useShowPane(() => {
    closeDictionaryDetails(history);
  });

  const [dateCreated, setDateCreated] = useState<moment.Moment | null>(null);
  const [dictionaryFilters, dispatchDF] = useReducer(
    dictionaryFiltersReducer,
    dictionaryFiltersReducerInitialState(dictionaryList.columns),
  );

  const filters = tableViewsFunctions || dictionaryFilters;

  const {
    hiddenDictionaryColumnIds,
    dictionaryColumnRanks,
    selectedTableView = {
      label: "Custom",
      value: null,
    },
  } = filters;

  const { wsTableViews } = dictionaryList;

  const taskOwner = useMemo(() => {
    return membersList?.find(
      (member) => member.id === dictionaryDetails?.ownerWsMemberUuid,
    );
  }, [dictionaryDetails?.ownerWsMemberUuid, membersList]);

  const foundDictionarySidebarItem = identity?.sidebarItems?.find(
    (item: { type: SidebarItemType; wsDictionaryUuid: string }) =>
      item.type === SidebarItemType.Dictionary &&
      item.wsDictionaryUuid === dictionaryDetails.wsDictionaryUuid,
  );

  const header = foundDictionarySidebarItem?.wsDictionaryName;

  useEffect(() => {
    if (!window.location.pathname.includes("/creator-database")) return;
    const cols = dictionaryList?.columns?.map((item) => ({
      ...item,
      options: item.data,
    }));

    getSelectDataSetForColumns(dispatch, cols);
  }, [dictionaryList?.columns]);

  useEffect(() => {
    dispatch(getDictionaryDetails(dictionaryUuid, dictionaryElementUuid));
    return () => {
      dispatch(clearDictionaryDetails());
    };
  }, [dictionaryUuid, dictionaryElementUuid]);

  useEffect(() => {
    if (dictionaryDetails?.created) {
      const dateValue = dictionaryDetails.created.date;
      if (dateValue !== null) {
        setDateCreated(convertToLocalTimezone(dateValue));
      }
    }
  }, [dictionaryDetails?.created]);

  const filteredMetadata = useMemo(
    () =>
      dictionaryDetails.metadata
        ?.filter((col) => !hiddenDictionaryColumnIds?.includes(col.uuid))
        .sort((a, b) => {
          const rankA = dictionaryColumnRanks?.find(
            (col: { uuid: string }) => col.uuid === a.uuid,
          )?.rank;
          const rankB = dictionaryColumnRanks?.find(
            (col: { uuid: string }) => col.uuid === b.uuid,
          )?.rank;

          if (rankA && rankB) return rankA.localeCompare(rankB);
          return 0;
        }) || [],
    [
      dictionaryDetails.metadata,
      hiddenDictionaryColumnIds,
      dictionaryColumnRanks,
    ],
  );

  return (
    <Pane
      className="dictionary-details"
      showPane={showPane}
      hidePane={hidePane}
    >
      {/* TODO FRONT: mamy juz enumy i klasy do linkow to mozna korzystac :) https://indahash.slack.com/archives/G4R8J0ZFU/p1695193798225969 */}
      <div className="dictionary-details__topbar">
        <Breadcrumbs>
          <BreadcrumbsItem
            text={header}
            to={`/workspace/${activeWorkspaceUuid}/dict/${header?.toLowerCase()}/${
              dictionaryDetails.wsDictionaryUuid
            }`}
          />
          <BreadcrumbsItem
            text={dictionaryDetails?.title}
            to={`/workspace/${activeWorkspaceUuid}/dict/${header?.toLowerCase()}/${
              dictionaryDetails.wsDictionaryUuid
            }?displayDictionaryPreview=${dictionaryElementUuid}`}
          />
        </Breadcrumbs>

        <div className="dictionary-details__topbar-buttons">
          <PreviewSettingsDropdown
            removeData={{
              objectNames: [dictionaryDetails.title],
              removeFunction: () => {
                closeDictionaryDetails(history);
                dispatch(
                  deleteDictionaryElement(dictionaryDetails.wsDictionaryUuid, [
                    dictionaryElementUuid,
                  ]),
                );
              },
            }}
          />

          <CloseIcon
            onClick={hidePane}
            className="dictionary-details__topbar-close-icon"
          />
        </div>
      </div>

      <div className="dictionary-details__content">
        <div className="dictionary-details__content-wrapper">
          <PreviewInfo
            context={PreviewContext.Dictionary}
            previewObjectUuid={dictionaryDetails.uuid}
            title={dictionaryDetails.title}
            titleMetaFieldUuid={dictionaryDetails?.titleMetaFieldUuid}
          />

          <TableViewSelector
            menuPlacement="auto"
            wsTableViews={wsTableViews}
            selectedWsTableView={selectedTableView}
            setSelectedWsTableView={(newValue: any) =>
              // @ts-ignore
              dispatchDF(setSelectedTableView(newValue))
            }
            setFilters={(newFilters: any, skipLS: boolean) =>
              // @ts-ignore
              dispatchDF(setDictionaryFilters(newFilters, skipLS))
            }
            setHiddenColumnIds={(columnIds: any, skipLS: boolean) =>
              // @ts-ignore
              dispatchDF(setHiddenDictionaryColumnIds(columnIds, skipLS))
            }
            setSortBy={(newValue: any, skipLS: boolean) =>
              // @ts-ignore
              dispatchDF(setDictionarySortBy(newValue, skipLS))
            }
            setSort={(newValue: any, skipLS: boolean) =>
              // @ts-ignore
              dispatchDF(setDictionarySort(newValue, skipLS))
            }
            columnIds={dictionaryList?.columns?.map((field) => field.uuid)}
            localStorageKey="ws-dictionary-settings"
            {...tableViewsFunctions}
          />
        </div>

        <DictionaryMetadata
          elementUuid={dictionaryElementUuid}
          metadata={filteredMetadata}
        />

        {taskOwner && (
          <AddedBy
            name={taskOwner.name}
            avatarUrl={taskOwner.avatarUrl}
            dateCreated={dateCreated}
          />
        )}
      </div>
    </Pane>
  );
}
