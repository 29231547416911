import React, { useMemo } from "react";

import { TaskType } from "src/types";
import { ITask } from "src/redux/task/taskReducer";
import { Column } from "src/redux/dictionary/types";
import { RootState } from "src/redux/reducers";
import { useSelector } from "react-redux"
import { IndeterminateCheckbox } from "./IndeterminateCheckbox";
import WorkspaceTasksTable from "./WorkspaceTasksTable";

interface WorkspaceTasksTableWrapperProps {
  taskType: TaskType;
  tasks: ITask[];
  columns: Column[];
}

export default function WorkspaceTasksTableWrapper({
  taskType,
  tasks,
  columns,
}: WorkspaceTasksTableWrapperProps) {
  const { workspaceTasksTotalRow } = useSelector(
    (state: RootState) => state.taskReducer,
  );

  const visibleColumns = useMemo(() => {
    const columnsCopy = [...columns];
    const metaFieldUuidsToHide: string[] = [];

    tasks.forEach((task) => {
      const foundMetaField = task.metadata.find(
        (metaField) => metaField.isSystem,
      );
      if (foundMetaField && foundMetaField.wsGlobalTaskMetaFieldUuid) {
        if (
          !metaFieldUuidsToHide.includes(
            foundMetaField.wsGlobalTaskMetaFieldUuid,
          )
        ) {
          metaFieldUuidsToHide.push(foundMetaField.wsGlobalTaskMetaFieldUuid);
        }
      }
    });

    return columnsCopy
      .filter((column) => !metaFieldUuidsToHide.includes(column.uuid))
      .map((column) => {
        return {
          id: column.uuid,
          header: column.name,
          footer: column.name,
          accessorKey: column.uuid,
          size: 180,
          columnFromApi: column,
          totalRow: workspaceTasksTotalRow[column.uuid],
        };
      });
  }, [
    tasks,
    columns,
    workspaceTasksTotalRow
  ]);

  const columnsForTable = useMemo(
    () => [
      {
        id: "drag-handle",
        accessorKey: "drag-handle",
        header: "",
        size: 48,
      },
      {
        id: "select",
        header: ({ table }: any) => (
          <IndeterminateCheckbox
            {...{
              table,
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row, table }: any) => (
          <IndeterminateCheckbox
            {...{
              row,
              table,
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        ),
        size: 48,
      },
      {
        id: taskType,
        header: taskType,
        footer: taskType,
        accessorKey: taskType,
        size: 240,
      },
      ...visibleColumns,
    ],
    [visibleColumns, taskType],
  );

  return (
    <WorkspaceTasksTable
      columns={columnsForTable}
      tableContext="workspaceTasksList"
    />
  );
}
